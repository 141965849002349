import { Table as BaseTable, TableContainer } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

export type TableProps = {
}

export const Table: React.FC<PropsWithChildren<TableProps>> = ({ children, ...props }) => {
  return (
    <TableContainer>
      <BaseTable {...props}>
        {children}
      </BaseTable>
    </TableContainer>
  )
}
