import React from 'react'
import { DomParser } from './domParser'
import { Disclaimer } from '../../components/disclaimer/disclaimer'

export const disclaimer: DomParser = (el, props) => {
  return (
    <Disclaimer>
      <Disclaimer textBlock>
        {props.children}
      </Disclaimer>
    </Disclaimer>
  )
}

disclaimer.match = (el, props) => {
  return props['data-disclaimer'] !== undefined
}
