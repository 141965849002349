import React, { PropsWithChildren } from 'react'
import { MobileMenuNavigationItem, MobileMenuNavigationItemProps } from './MobileMenuItem/MobileMenuNavigationItem'
import { MobileMenuNavigation, MobileMenuNavigationProps } from './MobileMenuNavigation'
import { MobileMenuWrapper, MobileMenuWrapperProps } from './MobileMenuWrapper'

export type MobileMenuProps =  (
  | ({ navigation?: false; item?: false; wrapper?: true} & MobileMenuWrapperProps)
  | ({ navigation?: true; item?: false; wrapper?: false } & MobileMenuNavigationProps)
  | ({ navigation?: false; item?: true; wrapper?: false } & MobileMenuNavigationItemProps)
)

export const MobileMenu: React.FC<PropsWithChildren<MobileMenuProps>> = ({ navigation, item, wrapper, ...props }) => {
  if (navigation) {
    return <MobileMenuNavigation { ...props as MobileMenuNavigationProps } />
  }

  if (item) {
    return <MobileMenuNavigationItem {...props as MobileMenuNavigationItemProps }/>
  }

  return (
    <MobileMenuWrapper { ...props as MobileMenuWrapperProps } />
  )
}
