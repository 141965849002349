import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select as SelectBase,
  SelectProps as SelectBaseProps,
} from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from '../icon/icon'
import { StyledFormLabel } from './StyledFormLabel'

export const SelectStyles = css`
  height: ${props => props.theme.typography.pxToRem(50)};
  font-size: ${props => props.theme.typography.pxToRem(17)};
  border: ${props => props.theme.typography.pxToRem(1)} ${props => alpha(props.theme.palette.text.secondary, 0.19)} solid;

  > label.MuiFormLabel-root {
    font-size: ${props => props.theme.typography.pxToRem(14)};
    padding-bottom: ${props => props.theme.typography.pxToRem(16)};
    font-weight: 500;

    ${props=> props.theme.breakpoints.up('sm')} {
      font-size: ${props => props.theme.typography.pxToRem(17)};
      padding-bottom: ${props => props.theme.typography.pxToRem(19)};
    }
  }

  > .MuiSelect-outlined {
    color: ${props => props.theme.palette.text.secondary};
  }

  &.Mui-error {
    border: ${props => props.theme.typography.pxToRem(1)} ${props => props.theme.palette.primary.main} solid;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  > .MuiOutlinedInput-input {
    padding-top: ${props => props.theme.typography.pxToRem(11)};
    padding-bottom: ${props => props.theme.typography.pxToRem(11)};
  }

  > .MuiSvgIcon-root {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    color: ${props => props.theme.palette.text.secondary};
    transform: translateX(-25%) translateY(${props => props.theme.typography.pxToRem(5)});
  }

  > fieldset {
    display: none;
  }

  &:hover {
    outline: 0;
    cursor: pointer;
  }

  &:focus-within {
    box-shadow: 0 ${props => props.theme.typography.pxToRem(3)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.palette.text.disabled};
  }
`

export const StyledSelect = styled(SelectBase)`
  ${props => props.variant === 'outlined' && SelectStyles}
`

export type SelectProps = {errorMessage?: string; options: Array<{value?: string; text?: React.ReactNode}>} & SelectBaseProps

export const Select: React.FC<PropsWithChildren<SelectProps>> = (props) => {
  const { label, options, errorMessage } = props

  return (
    <FormControl fullWidth>
      {label && (<StyledFormLabel>{label}</StyledFormLabel>) }
      <StyledSelect {...props}
        variant="outlined"
        IconComponent={() => (<Icon icon={faChevronDown} className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" />)}
      >
        {
          options.map((option, index)=>(<MenuItem key={index} value={option.value}>{option.text}</MenuItem>))
        }
      </StyledSelect>
      {errorMessage && (<FormHelperText>{errorMessage}</FormHelperText>)}
    </FormControl>
  )
}