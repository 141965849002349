import React, { PropsWithChildren } from 'react'
import {
  StyledDisclaimerTextBlock,
  StyledDisclaimerTextBlockContainer,
  StyledDisclaimerTitle,
  StyledDisclaimerWrapper,
  StyleDisclaimerTitleContainer,
} from './styleddisclaimer'

type DisclaimerTitleProps = React.ComponentProps<typeof StyledDisclaimerTitle> & {
  //
}

const DisclaimerTitle: React.FC<PropsWithChildren<DisclaimerTitleProps>> = ({ ...props }) => (
  <StyleDisclaimerTitleContainer>
    <StyledDisclaimerTitle variant="h5" { ...props}>
      {props.children}
    </StyledDisclaimerTitle>
  </StyleDisclaimerTitleContainer>
)

type DisclaimerTextBlockProps = React.ComponentProps<typeof StyledDisclaimerTextBlock> &  {
  //
}

const DisclaimerTextBlock: React.FC<PropsWithChildren<DisclaimerTextBlockProps>> = ({ ...props }) => {
  return (
    <StyledDisclaimerTextBlockContainer>
      <StyledDisclaimerTextBlock variant="body1" { ...props}>
        {props.children}
      </StyledDisclaimerTextBlock>
    </StyledDisclaimerTextBlockContainer>
  )
}


export type DisclaimerProps = ({ title?: boolean;  textBlock?: boolean }) & (
  // Title
  | ({ title: true } & DisclaimerTitleProps)
  // Text block
  | ({ textBlock: true } & DisclaimerTextBlockProps)
  // Wrapper
  | React.ComponentProps<typeof StyledDisclaimerWrapper>
)

export const Disclaimer: React.FC<PropsWithChildren<DisclaimerProps>> = ({ title, textBlock, ...props }) => {
  if (title) {
    return (
      <DisclaimerTitle {...props}>
        {props.children}
      </DisclaimerTitle>
    )
  }

  if (textBlock) {
    return (
      <DisclaimerTextBlock {...props}>
        {props.children}
      </DisclaimerTextBlock>
    )
  }

  return (
    <StyledDisclaimerWrapper {...props}>
      {props.children}
    </StyledDisclaimerWrapper>
  )
}

