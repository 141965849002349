import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import { isNotNull } from '../../utils/nonNull'
import { Link, LinkProps } from '../link/link'
import { StyledCardList, StyledTitleChevron, StyledTitleLinksWrapper, StyledTitleText } from './styledNavDropdownLinkCard'

export type NavDropdownLinkCardProps = React.ComponentProps<typeof Grid> & Pick<LinkProps, 'href'> & {
  title: string
  target: boolean
}

export const NavDropdownLinkCard: React.FC<PropsWithChildren<NavDropdownLinkCardProps>> = ({ title, href, children, target, ...props }) => {
  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" {...props}>
        <StyledTitleLinksWrapper item>
          {isNotNull(href) && href !== '' ? (
            <Link href={href} target={target ? '_blank' : '_self'}>
              <StyledTitleText title={title}>
                {title}
                <StyledTitleChevron icon={faChevronRight} />
              </StyledTitleText>
            </Link>
          ) : (
            <Link href={href} target={target ? '_blank' : '_self'}>
              <StyledTitleText title={title}>
                {title}
              </StyledTitleText>
            </Link>
          )}
        </StyledTitleLinksWrapper>
      </Grid>
      <Grid container>
        <StyledCardList>
          {children}
        </StyledCardList>
      </Grid>
    </>
  )
}
