import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const StyledErrorContainer = styled.div`
  display: block;
  padding: 2em;
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.palette.text.disabled};
  background: rgba(0, 0, 0, 0.1);
`

export class SimpleBoundary extends React.Component<{ text?: string; children: React.ReactNode }, { error?: Error | string; errorInfo?: React.ErrorInfo }> {
  constructor(props) {
    super(props)

    this.state = {
      error: undefined,
      errorInfo: undefined,
    }
  }

  componentDidCatch(error: Error | string, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo })
    console.warn('Caught error', errorInfo)
    console.log({ error, errorInfo })
  }

  render() {
    return this.state.error ? (
      <StyledErrorContainer>
        Error: {typeof this.state.error === 'string' ? this.state.error : this.state.error.message}
      </StyledErrorContainer>
    ) : this.props.children
  }
}

export type WithErrorBoundaryHoC = <T extends React.ComponentType | keyof JSX.IntrinsicElements = React.ComponentType>(Component: T) => React.ComponentType<ComponentProps<T>>

export const withSimpleBoundary: WithErrorBoundaryHoC = (Component) => (props) => (
  <SimpleBoundary>
    {/** @ts-ignore */}
    <Component { ...props } />
  </SimpleBoundary>
)