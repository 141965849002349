import { useMediaQuery } from '@material-ui/core'
import React, { ComponentProps, PropsWithChildren } from 'react'
import LazyHydrate from 'react-lazy-hydration'
import { useTheme } from 'styled-components'

export type LazyComponentProps = Omit<ComponentProps<typeof LazyHydrate>, 'whenVisible' | 'whenIdle'> & {
  //
}

export const LazyComponent: React.FC<PropsWithChildren<LazyComponentProps>> = ({ children, ...props }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <LazyHydrate whenVisible={!isDesktop} whenIdle={isDesktop} { ...props }>
      {children}
    </LazyHydrate>
  )
}