import React, { PropsWithChildren } from 'react'
import { UtilityBarCard, UtilityBarCardProps } from './NavDropdownUtilityBarCard'
import { UtilityBarWrapper, UtilityBarWrapperProps } from './NavDropdownUtilityBarWrapper'

export type NavDropdownUtilityBarProps =  (
  | ({ card?: false } & UtilityBarWrapperProps)
  | ({ card: true } & UtilityBarCardProps)
)

export const NavDropdownUtilityBar: React.FC<PropsWithChildren<NavDropdownUtilityBarProps>> = ({ card, ...props }) => {
  if (card) {
    return (
      <UtilityBarCard { ...props as UtilityBarCardProps}/>
    )
  }

  return (
    <UtilityBarWrapper { ...props as UtilityBarWrapperProps } />
  )
}
