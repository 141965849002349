import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { Icon } from '../../icon/icon'
import { MobileMenuContext } from '../MobileMenuContext'
import { StyledListItem, StyledListItemGrid } from './styledMobileMenuItem'

export const StyledBackContainer = styled.span`
  display: flex;
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(15)};
  font-weight: 500;
  text-decoration: underline;
`

export const StyledBackCheveron = styled(Icon)`
  position: relative;
  top: 4px;
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(12)};
  font-weight: 500;
  margin-right: ${props => props.theme.typography.pxToRem(8)};
  margin-top: ${props => props.theme.typography.pxToRem(2)};
`

export type MobileMenuBackItemProps = React.ComponentProps<typeof StyledListItem> & {
  level: number
  idx: number
}

export const MobileMenuBackItem: React.FC<PropsWithChildren<MobileMenuBackItemProps>> = ( { children, level, idx, ...props } ) => {
  const menuContext = useContext(MobileMenuContext)
  const onBack = (event) => {
    event.stopPropagation()
    menuContext.setMenuClose(level, idx)
  }

  return (
    <StyledListItem $isLink={true} $back onClick={onBack} {...props}>
      <StyledListItemGrid container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item>
          <StyledBackContainer>
            <StyledBackCheveron icon={faChevronLeft}/>
            { children }
          </StyledBackContainer>
        </Grid>
      </StyledListItemGrid>
    </StyledListItem>
  )
}
