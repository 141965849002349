import { Typography } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Link, LinkProps } from '../link/link'

export type NavDropdownLinkItemProps = React.ComponentProps<typeof StyledLinksItem> & Pick<LinkProps, 'href'> & {
  target: boolean
}

export const StyledLinksItemText = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(17)};
  line-height: ${props => props.theme.typography.pxToRem(22)};
  margin-bottom: 0;
  transition: color 0.2s ease;
`

export const StyledLinksItem = styled.div`
  cursor: pointer;
  margin-bottom: ${props => props.theme.typography.pxToRem(16)};

  > * {
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.palette.text.secondary};

    &:hover {
      color: ${props => props.theme.palette.secondary.dark};
      text-decoration-color: ${props => props.theme.palette.secondary.dark};

      > ${StyledLinksItemText} {
        color: ${props => props.theme.palette.secondary.dark};
      }
    }
  }
`

export const NavDropdownLinkItem: React.FC<PropsWithChildren<NavDropdownLinkItemProps>> = ({ href, children, target, passHref, ...props }) => {
  return (
    <StyledLinksItem title={children} {...props}>
      <Link target={target ? '_blank' :'_self'} href={href !== '' ? href : '#'} >
        <StyledLinksItemText>{ children }</StyledLinksItemText>
      </Link>
    </StyledLinksItem>
  )
}
