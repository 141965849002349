import { createContext } from 'react'
import { SiteInfoFragment } from './functions/__generated__/header'
import { FooterQuery } from './functions/__generated__/footer'
import { FallbackQuery } from './functions/__generated__/fallback'

export type SeoGlobals = {
  title: string
  meta: string
  link: string
  jsonLd: string
  noindex: boolean
  id: string
}
export const seoGlobalsDefault = {
  title: '',
  meta: '',
  link: '',
  jsonLd: '',
  noindex: false,
  id:'',
}
export type HeaderGlobals = {
  navigation: HeaderNavigation
  siteInfo: SiteInfoFragment
}
export const headerGlobalsDefault: HeaderGlobals = {
  navigation: { nodes: [] },
  siteInfo: {},
}
export type HeaderNavigation = {
  nodes: NodeTypeWithData[]
}
export type FooterGlobals = FooterQuery
export const footerGlobalsDefault: FooterGlobals = {}
export type FallbackGlobals = FallbackQuery
export const fallbackDefault: FallbackGlobals = {}
export type GlobalContextType = {
  seo: SeoGlobals
  header: HeaderGlobals
  footer: FooterGlobals
  fallback: FallbackGlobals
}

export const GlobalContext = createContext<GlobalContextType>({
  seo: seoGlobalsDefault,
  header: headerGlobalsDefault,
  footer: footerGlobalsDefault,
  fallback: fallbackDefault,
})

export type LayoutContext = {
  path: string
}
export type NodeTypeWithData = {
  title: string
  url: string
  type: string | null
  data: Record<string, unknown>
  children: NodeTypeWithData[] | null
  newWindow: string
  altOverviewPageLink: string | null
}