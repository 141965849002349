import { Grid } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import styled, { css } from 'styled-components'
import { rem } from '../../utils'
import { StyledNavItem } from './NavItem'


export const StyledDropdownPanel = styled(Grid)`
  cursor: default;
  width: 100%;
  padding-top: ${props => props.theme.typography.pxToRem(60)};
  padding-bottom: ${props => props.theme.typography.pxToRem(100)};

  > .MuiGrid-item {
    padding: 0 !important;
    width: 100%;

    > .MuiGrid-align-items-xs-flex-start {
      gap: ${props => props.theme.typography.pxToRem(25)};
    }
  }

  > .MuiGrid-container {
    padding-top: ${props => props.theme.typography.pxToRem(48)};
  }
`

export const StyledLogoWrapper = styled.div<{ $containerWidth: number; $sticky: boolean }>`
  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.$sticky ?  props.theme.typography.pxToRem(11) : props.theme.typography.pxToRem(15)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    margin-top: ${props => props.$sticky ? props.theme.typography.pxToRem(0) : props.theme.typography.pxToRem(5)};
    position: absolute;
    transition: top 0.2s ease-in-out;
    left: ${props => props.theme.typography.pxToRem(65)};
  }

  ${props => props.theme.breakpoints.up('xl')} {
    left: ${props => props.theme.typography.pxToRem((props.$containerWidth-1920)/2 + 65)};
    margin-top: 0;
  }
`

type NavProps = { $secondary?: boolean; $sticky?: boolean; $visibleHeader?: boolean; $isOpen?: boolean }

const headerHeight = css<NavProps>`
  height: ${props => props.theme.typography.pxToRem(74)};

  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.theme.typography.pxToRem(104)};

    ${props => props.$sticky && css`
      height: ${props => props.theme.typography.pxToRem(74)};
    `}
  }
`

export const StyledNavBuffer = styled.div<NavProps>`
  height: ${props => props.theme.typography.pxToRem(74)};

  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.theme.typography.pxToRem(104)};
  }
`

export const StyledNavigationContainer = styled(AppBar)<NavProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: ${props => props.theme.palette.primary.dark};
  box-shadow: none;
  background-color: white;
  width: 100%;
  padding: ${props => props.theme.typography.pxToRem(20)} ${props => props.theme.typography.pxToRem(24)} 0;
  border-bottom: ${props => props.$sticky ? props.theme.typography.pxToRem(1) : '0'} solid rgba(0, 0, 0, 0.1);
  transition-property: height, transform, background-color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-in-out !important;
  ${headerHeight}

  &:focus-within {
    backdrop-filter: blur(10px);
  }

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props =>  props.theme.typography.pxToRem(props.$sticky? 10: 20)} ${props => props.theme.typography.pxToRem(24)} 0;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    align-items: center;
    padding: 0;

    ${StyledLogoWrapper} {
      top: ${props => props.theme.typography.pxToRem(props.$sticky ? 12 : 20)};
    }
  }

  ${props => props.theme.breakpoints.up('xl')} {
    max-width: 100%;
    right: 0;
  }

  ${StyledNavItem} {
    &::after {
      transform: translateY(10px);
    }

    padding-top: ${props => (props.$visibleHeader && (props.theme.typography.pxToRem(props.$sticky? 10: 16))) || ( !props.$visibleHeader &&  props.theme.typography.pxToRem(props.$sticky? 10: 16)  )};
    padding-bottom: ${props => props.$visibleHeader && (props.$secondary ? props.theme.typography.pxToRem(32) : props.theme.typography.pxToRem(40))};

    ${props => props.theme.breakpoints.up('lg')} {
      padding-top: ${props => props.$visibleHeader && props.theme.typography.pxToRem(32)};
      padding-bottom: ${props => props.$visibleHeader && props.theme.typography.pxToRem(32)};
    }
  }

  ${StyledDropdownPanel} {
    top: ${props => props.theme.typography.pxToRem(68)};
  }
`

export const StyledRightMenuContainer = styled.div<{$containerWidth: number}>`
  display: flex;
  align-items: center;
  margin-left: auto;
  order: 2;
  flex: 0 0 auto;
  column-gap: ${props => rem(6, props.theme)};

  ${props => props.theme.breakpoints.up(360)} {
    column-gap: ${props => rem(20, props.theme)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${props => props.theme.typography.pxToRem(32)};
    grid-gap: ${props => props.theme.typography.pxToRem(28)};
  }

  ${props => props.theme.breakpoints.up('xl')} {
    right: ${props => props.theme.typography.pxToRem((props.$containerWidth-1920)/2 + 32)};
  }
`

export const StyledNavItemContainer = styled(Grid)<{$sticky?: boolean}>`
  & > div > div > a {
    &:hover {
      color: ${props => props.$sticky ? props.theme.palette.primary.main : props.theme.palette.text.primary};
    }
  }
`