import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { Container, Grid, Link } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import React, { Dispatch, MutableRefObject, PropsWithChildren, SetStateAction, createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useContainerDimensions } from '../../utils/dimension'
import { isNotNull } from '../../utils/nonNull'
import { CreditUnionIcon } from '../icon/CreditUnionIcon'
import { Icon } from '../icon/icon'
import { StyledCreditUnionLogo as CreditUnionLogo } from '../logo/logo'
import { SearchBar } from '../searchBar/SearchBar'
import { StyledBrokenBarIcon, StyledContactUs, StyledIconNavItem, StyledLoginButton } from './StyledNavComponents'
import {
  StyledLogoWrapper,
  StyledNavBuffer,
  StyledNavItemContainer,
  StyledNavigationContainer,
  StyledRightMenuContainer,
} from './StyledNavContainers'

export type PrimaryNavigationProps = {
  secondary?: boolean
  BSB: string
  LoginLink: string
  openMobileMenu?: () => void
}

export type HideOnScrollType = {
  window?: () => Window
  children: React.ReactElement
}

type PrimaryNavigationContextType = {
  searchIsOpen: boolean
  secondary: boolean
  setSearchIsOpen: Dispatch<SetStateAction<boolean>>
}

export const PrimaryNavigationContext = createContext<PrimaryNavigationContextType>({
  secondary: false,
  searchIsOpen: false,
  setSearchIsOpen: () => {},
})

export const PrimaryNavigation: React.FC<PropsWithChildren<PrimaryNavigationProps>> = ({ BSB, LoginLink, secondary = false, openMobileMenu, children }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  //Should use breakpoints.up to get correct boolean value instead of breakpoints.up
  const isNotTablet = useMediaQuery(theme.breakpoints.up('md'))
  const useDimensionRef = useRef() as MutableRefObject<HTMLDivElement>
  const { width } = useContainerDimensions(useDimensionRef)
  
  // Navbar State
  const [isNavSticky, setIsNavSticky] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [visibleHeader, setVisibleHeader] = useState(true)

  const handleNavigation = useCallback((e) => {
    const window = e.currentTarget

    if (window) {
      if (!isNavSticky && window.scrollY >= 80) {
        setIsNavSticky(true)
      }
      else if(isNavSticky && window.scrollY >= 140) {
        setVisibleHeader(false)
      }
      else if (isNavSticky && window.scrollY < 80) {
        setIsNavSticky(false)
        setVisibleHeader(true)
      }
    }
  }, [isNavSticky])

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleNavigation)
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', handleNavigation)
      }
    }
  }, [handleNavigation])

  // Trigger for Navbar Slider
  const trigger = useScrollTrigger({ target: global as unknown as Window })

  // Conditioned Icons
  const bsbMenuItem = isDesktop && isNotNull(BSB) && BSB.length>0 && (
    <StyledIconNavItem>
      <StyledContactUs href="/help-and-support/contact-us">Contact Us</StyledContactUs>
    </StyledIconNavItem>
  )

  const loginMenuItem = isNotNull(LoginLink) && LoginLink.length>0 ? (
    <StyledIconNavItem>
      <StyledLoginButton href={LoginLink} target={'_blank'} buttonColor="orange" startIcon={<Icon icon={faLock} />} primary>Login</StyledLoginButton>
    </StyledIconNavItem>
  ) : null

  return (
    <PrimaryNavigationContext.Provider value={{ secondary, searchIsOpen: isOpen, setSearchIsOpen: setIsOpen }}>    
      <StyledNavBuffer $secondary={secondary} $sticky={isNavSticky} $visibleHeader={visibleHeader}/>
      <Slide appear={false} direction="down" in={!trigger}>
        <StyledNavigationContainer ref={useDimensionRef} $secondary={secondary} $sticky={isNavSticky} $visibleHeader={visibleHeader} $isOpen={isOpen}>
          <StyledLogoWrapper $sticky={isNavSticky} $containerWidth={width}>
            <Link href={'/'}>
              <CreditUnionLogo notext={isNavSticky} />
            </Link>
          </StyledLogoWrapper>
          {isNotTablet && isOpen == false && (
            <Container maxWidth={'lg'}>
              <Grid container justifyContent={'center'}>
                <StyledNavItemContainer $sticky={isNavSticky} item lg={10}>
                  {children}
                </StyledNavItemContainer>
              </Grid>
            </Container>
          )}
          <StyledRightMenuContainer $containerWidth={width}>
            {!isOpen && bsbMenuItem}
            {!isDesktop ? (
              <>
                <SearchBar />
                {!isOpen && loginMenuItem}
              </>
            ) : (
              <>
                {!isOpen && loginMenuItem}
                <SearchBar sticky={isNavSticky}/>
              </>
            )}
            {!isNotTablet && (
              <StyledBrokenBarIcon aria-label="menu" onClick={openMobileMenu}>
                <CreditUnionIcon iconCode={'hamburger'} width={29} height={20} />
              </StyledBrokenBarIcon>
            )}
          </StyledRightMenuContainer>
        </StyledNavigationContainer>
      </Slide>
    </PrimaryNavigationContext.Provider>
  )
}
