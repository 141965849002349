import { Alert, AlertProps } from '@material-ui/lab'
import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

export const StyledAlert = styled(Alert)<{ $type?: AlertNoticeProps['type'] }>`
  line-height: ${props => props.theme.typography.pxToRem(35)};

  .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-message {
    font-size: ${props => props.theme.typography.pxToRem(14)};
    padding: ${props => props.theme.typography.pxToRem(24)} ${props => props.theme.typography.pxToRem(29)} ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(32)};

    ${props => props.theme.breakpoints.up('sm')} {
      font-size: ${props => props.theme.typography.pxToRem(17)};
      padding: ${props => props.theme.typography.pxToRem(36)} ${props => props.theme.typography.pxToRem(30)};
    }

    ${props => props.theme.breakpoints.up('md')} {
      font-size: ${props => props.theme.typography.pxToRem(17)};
    }
  }

  a {
    color: ${props => props.theme.palette.secondary.main};
  }

  a:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }

  ${props => props.$type === undefined && css`
    color: #1c1f2a;
    background-color: #f2f2f2;
  `}

  ${props => props.$type === 'light' && css`
    color: #1c1f2a;
    background-color: #fff7e6;
  `}

  ${props => props.$type === 'dark' && css`
    color: white;
    background-color: ${(props) => props.theme.palette.text.primary};
  `}
`

export type AlertNoticeProps = AlertProps & {
  type?: 'light' | 'dark'
};

export const AlertNotice: React.FC<PropsWithChildren<AlertNoticeProps>> = ({ type, ...props }) => <StyledAlert $type={type} { ...props } />
